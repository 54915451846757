export const environment = {
  production: false,
  base: window['env']['BACKEND_URL'],
  andonDisplay: window['env']['ANDON_URL'],
};

export const environmentUrls = {
  auth: environment.base + "api/v1/users/auth",
  register: environment.base + "api/v1/users/auth/register",
  login: environment.base + "api/v1/users/auth/login",
  forgotPassword: environment.base + "api/v1/users/auth/forgotPassword/",
  productsDetailed: environment.base + "api/v1/products/",
  product: environment.base + "api/v1/products/",
  productCategory: environment.base + "api/v1/products/categories",
  documentUploadDelete: environment.base + "api/v1/documents",
  documentUploadFullRoute: environment.base + "api/v1/documents/fullroute",
  documentPublic: environment.base + "api/v1/documents/file/public/",
  documentDownload: environment.base + "api/v1/documents/file/",
  documentInfo: environment.base + "api/v1/documents/info/",
  documentInfos: environment.base + "api/v1/documents/bucket/",
  documentInfosNew: environment.base + "api/v1/documents/bucket/new/",
  companies: environment.base + "api/v1/partners/companies/",
  companyGroups: environment.base + "api/v1/partners/companygroups/",
  financial: environment.base + "api/v1/partners/financialinfos/",
  address: environment.base + "api/v1/locations/addresses/",
  geo: environment.base + "api/v1/locations/geo/",
  order: environment.base + "api/v1/orders/",
  contact: environment.base + "api/v1/partners/contacts",
  backup: environment.base + "api/v1/system/backups",
  audit: environment.base + "api/v1/system/audit",
  health: environment.base + "api/v1/system/health",
  info: environment.base + "api/v1/system/info",
  countries: environment.base + "api/v1/locations/countries",
  users: environment.base + "api/v1/users",
  roles: environment.base + "api/v1/roles",
  home: environment.base + "api/v1/system/home",
  operators: environment.base + "api/v1/resources/operators/",
  role: environment.base + "api/v1/resources/operators/roles/",
  faq: environment.base + "api/v1/questions",
  project: environment.base + "api/v1/project/",
  projectCost: environment.base + "api/v1/project/cost/",
  planStore: environment.base + "api/v1/plans/PlanStore/",
  masterPlan: environment.base + "api/v1/plans/PlanStore/master/",
  resourcePlan: environment.base + "api/v1/plans/resourcePlan/",
  solver: environment.base + "api/v1/Solver",
  mergeMaster: environment.base + "api/v1/plans/Planstore/merge/",
  skillMatrix: environment.base + "api/v1/resources/operators/skills/matrix/",
  skillUpsert: environment.base + "api/v1/resources/operators/skills/upsert/",
  planListView: environment.base + "api/v1/plans/resourcePlan/planList",
  getTaskFilter: environment.base + "api/v1/plans/task/",
  technologist: environment.base + "api/v1/technologist/terms",
  machines: environment.base + "api/v1/machines",
  //tools: environment.base + "api/v1/resources/tools",
  maintenanceTasks: environment.base + "api/v1/maintenance/task",
  maintenanceTaskCategories: environment.base + "api/v1/maintenance/taskcategory",
  maintenanceEvents: environment.base + "api/v1/maintenance/event",
  andons: environment.base + "api/v1/andons",
  andonStatus: environment.base + "api/v1/andons/status",
  machineLocation: environment.base + 'api/v1/machines/locations',
  machineGroup: environment.base + 'api/v1/machines/groups',
  regions: environment.base + "api/v1/locations/regions",
  getSubType: environment.base + "api/v1/partners/companies/subType",
  getType: environment.base + "api/v1/partners/companies/type",
  tools: environment.base + "api/v1/resources/tools",
  salesLeads: environment.base + "api/v1/sales/leads",
  companySubTypes: environment.base + "api/v1/partners/companies/subType",
  companyTypes: environment.base + "api/v1/partners/companies/type",
  vtsz: environment.base + "api/v1/logistics/vtsz",
  unit: environment.base + "api/v1/products/units",
  states: environment.base + "api/v1/locations/states",
  salesOpportunity: environment.base + "api/v1/sales/opportunity",
  settings: environment.base + "api/v1/system/settings",
  allocations: environment.base + "api/v1/resources/allocations",
  options: environment.base + "api/v1/options/",
  workInProgresses: environment.base + "api/v1/workinprogress/",
  scraps: environment.base + "api/v1/scrap",
  capacityTemplate: environment.base + "api/v1/capacity/template",
  dayTemplate: environment.base + "api/v1/day/template",
  weekTemplate: environment.base + "api/v1/week/template",
  resource: environment.base + "api/v1/resource",

  //Barcode  
  barcodedProducts: environment.base + "api/v1/barcode/products",
  storageAreas: environment.base + "api/v1/barcode/storageArea",
  stockInformations: environment.base + "api/v1/barcode/stockInfo",
  warehouseTransactions: environment.base + "api/v1/barcode/warehouseTrans",

  //MSP specific
  mspProduct: environment.base + "api/v1/msp/products/",
  mspOptions: environment.base + "api/v1/msp/options/",
  mspProductMatching: environment.base + "api/v1/msp/matching/",
  mspProductMatchingHistory: environment.base + "api/v1/msp/matching/history",
  mspCompanies: environment.base + "api/v1/msp/partners/companies/",
  schemas: environment.base + "api/v1/msp/material/productschema/",
  color: environment.base + "api/v1/msp/material/productcolor",
  materialFormat: environment.base + "api/v1/msp/material/format",
  materialType: environment.base + "api/v1/msp/material/type",
  packagingFormat: environment.base + "api/v1/msp/material/packagingformat",
  materialName: environment.base + "api/v1/msp/material/subgroup",
  materialGroup: environment.base + "api/v1/msp/material/group",
  mspDashboard: environment.base + "api/v1/msp/statistics",

  //FS specific
  fsResourceTool: environment.base + "api/v1/fs/resources/tools/",

  //Fittingker
  fittingkerMachineTask: environment.base + "api/v1/fittingker/machines/task/",
  fittingkerMachine: environment.base + "api/v1/fittingker/machines/",
  fittingkerMachineDetails: environment.base + "api/v1/fittingker/machines/details/",
  fittingkerOrder: environment.base + "api/v1/fittingker/orders/",
  fittingkerProduct: environment.base + "api/v1/fittingker/product/",
  fittingkerCapacity: environment.base + "api/v1/fittingker/capacity/",
  fittingkerHoliday: environment.base + "api/v1/fittingker/holiday/",
  fittingkerStatistics: environment.base + "api/v1/fittingker/statistics",
  fittingkerImport: environment.base + "api/v1/import/fittingker",
  fittingkerImportCooperation: environment.base + "api/v1/import/fittingker/cooperation",
  fittingkerImportStatus: environment.base + "api/v1/import/fittingker/status",
  walterTransactions: environment.base + "api/v1/fittingker/walterTransactions",
  fittingkerProductionOrder: environment.base + "api/v1/fittingker/production/order",
  fittingkerSchedule: environment.base + "api/v1/fittingker/machines/task/schedule",
  fittingkerMaterialRequirement: environment.base + "api/v1/fittingker/materialrequirement",
  fittingkerToggleLockMasterTask: environment.base + "api/v1/fittingker/machines/task/lock",
  fittingkerMasterMemento: environment.base + "api/v1/import/fittingker/memento/master",
  fittingkerCooperationMemento: environment.base + "api/v1/import/fittingker/memento/cooperation",
  //Korona specific
  productionCycles: environment.base + "api/v1/korona/productionCycle",
  harvests: environment.base + "api/v1/korona/harvest",
  resourceDatas: environment.base + "api/v1/korona/resourceData",
  enrichmentMaterial: environment.base + "api/v1/korona/enrichmentMaterial",
  mushroomHouse: environment.base + "api/v1/korona/mushroomHouse",
  soil: environment.base + "api/v1/korona/soil",
  spawn: environment.base + "api/v1/korona/spawn",
  type: environment.base + "api/v1/korona/type",
  koronaStatistics: environment.base + "api/v1/korona/statistics",
  peats: environment.base + "api/v1/korona/peat",
  peatLabs: environment.base + "api/v1/korona/peat/peatLab",
  koronatruck: environment.base + "api/v1/korona/truck",
  //options: environment.base + "api/v1/korona/options/",
  disease: environment.base + "api/v1/korona/disease",
  irrigations: environment.base + "api/v1/korona/productionCycle/irrigation",
  productionByTrucks: environment.base + "api/v1/korona/productionCycle/truck",
  klavCode: environment.base + "api/v1/korona/klavCode",
  material: environment.base + "api/v1/korona/spawnLab/material",
  micelium: environment.base + "api/v1/korona/spawnLab/micelium",
  motherSpawn: environment.base + "api/v1/korona/spawnLab/motherSpawn",
  vaccine: environment.base + "api/v1/korona/spawnFactory/vaccine",
  spawnMaterial: environment.base + "api/v1/korona/spawnFactory/spawnMaterial",
  spawnElement: environment.base + "api/v1/korona/spawnFactory/spawnElement",
  spawnLab: environment.base + "api/v1/korona/spawnLab",
  spawnFactory: environment.base + "api/v1/korona/spawnFactory",
  compostLab: environment.base + "api/v1/korona/compost/lab",
  compostPhase1: environment.base + "api/v1/korona/compost/phase1",
  compostPhase2: environment.base + "api/v1/korona/compost/phase2",
  compostPhase3: environment.base + "api/v1/korona/compost/phase3",
  compost: environment.base + "api/v1/korona/compost",

  //Nadudvari
  controlSnapshots: environment.base + "api/v1/nadudvari/control-snapshots",
  errors: environment.base + "api/v1/nadudvari/errors",
  production: environment.base + "api/v1/nadudvari/productions",
  shiftlogs: environment.base + "api/v1/nadudvari/shiftlogs",
  nadudvariProduct: environment.base + "api/v1/nadudvari/products",
  demands: environment.base + "api/v1/nadudvari/demands",
  demandMatrix: environment.base + "api/v1/nadudvari/demands/matrix",
  uploadDemand: environment.base + "api/v1/nadudvari/demands/upload",
  stockUpsert: environment.base + "api/v1/nadudvari/demands/upsert",
  nadudvariRecalculate: environment.base + "api/v1/nadudvari/demands/recalculate",
  nadudvariPlans: environment.base + "api/v1/nadudvari/plans",
  nadudvariPlanDemands: environment.base + "api/v1/nadudvari/plans/matrix",
  nadudvariUploadPlan: environment.base + "api/v1/nadudvari/plans/upload",
  nadudvariUploadPlanDemands: environment.base + "api/v1/nadudvari/plans/uploadDemand",
  nadudvariUploadExcelPlan: environment.base + "api/v1/nadudvari/plans/upload-excel",
  nadudvariProductionPlan: environment.base + "api/v1/nadudvari/production_plan",
  nadudvariProductionPlanDemands: environment.base + "api/v1/nadudvari/production_plan/matrix",
  nadudvariStatistics: environment.base + "api/v1/nadudvari/statistics",
  nadudvariDailyProduction: environment.base + "api/v1/nadudvari/dailyProduction",
  nadudvariAndonMessage: environment.base + "api/v1/nadudvari/message",
  nadudvariMachines: environment.base + "api/v1/nadudvari/machines",
  nadudvariTransaction: environment.base + "api/v1/nadudvari/transactions",
  nadudvariIngredient: environment.base + "api/v1/nadudvari/ingredients",
  nadudvariSchedulerDaily: environment.base + "api/v1/nadudvari/scheduler/daily",
  nadudvariWeekScheduler: environment.base + "api/v1/nadudvari/scheduler/solver",
  nadudvariJams: environment.base + "api/v1/nadudvari/jams",
  nadudvariClAttributes: environment.base + "api/v1/nadudvari/cleaning/attributes",
  nadudvariClRules: environment.base + "api/v1/nadudvari/cleaning/rules",
  nadudvariAndons: environment.base + "api/v1/nadudvari/andons", 
  //  Lab
  measurements: environment.base + "api/v1/lab/measurements",
  labMachines: environment.base + "api/v1/lab/machines",
};

export const mapboxToken: string = "pk.eyJ1IjoiZGFuaWVsazk5IiwiYSI6ImNsa2dubXNxbzAyZXUzZm56bWZ6OG5jdDkifQ.AnJWzACkNlRLCRWkN-DzbA";